import { template as template_2109bb1e87f34f4990785c7bcc5da850 } from "@ember/template-compiler";
const FKLabel = template_2109bb1e87f34f4990785c7bcc5da850(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
