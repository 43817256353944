import { template as template_d92407acc7b048a0a321c7ea490cf507 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import { eq } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
import PluginOutlet from "./plugin-outlet";
const ConditionalLoadingSpinner = template_d92407acc7b048a0a321c7ea490cf507(`
  <PluginOutlet
    @name="conditional-loading-spinner"
    @defaultGlimmer={{true}}
    @outletArgs={{hash condition=@condition size=@size}}
  >
    <div
      class={{concatClass
        "loading-container"
        (if @condition "visible")
        (if (eq @size "small") "inline-spinner")
      }}
    >
      {{#if @condition}}
        <div class="spinner {{@size}}"></div>
      {{else}}
        {{yield}}
      {{/if}}
    </div>
  </PluginOutlet>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ConditionalLoadingSpinner;
