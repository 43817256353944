import { template as template_d2a260ea786143e296f79a393d6c43fb } from "@ember/template-compiler";
const FKControlMenuContainer = template_d2a260ea786143e296f79a393d6c43fb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
